import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ModalSlice {
    openInformativeModal: boolean,
}

const initialState: ModalSlice = {
    openInformativeModal: false
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleOpenInformativeModal: (state, action: PayloadAction<boolean>) => {
            state.openInformativeModal = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { 
    toggleOpenInformativeModal
} = modalSlice.actions