import { useContext } from "react";
import { LocationContext } from "../context";
import ElSalvadorPage from "./ElSalvadorPage";
import RepDominicanaPage from "./RepDominicanaPage";
import { countriesCode } from "../helpers/countriesCode";

const HomePage = () => {
    const { countryCode } = useContext(LocationContext);

    switch (countryCode) {
        case countriesCode.Honduras:
        case countriesCode.Colombia:
        case countriesCode.Guatemala:
        case countriesCode.ElSalvador:
            return <ElSalvadorPage /> 
        case countriesCode.RepDominicana:
            return <RepDominicanaPage />
        default:
            return <ElSalvadorPage />
    }
}

export default HomePage;