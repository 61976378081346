import { BarllenoProduct } from "../types/ElSalvadorTypes";

// Images
import AlbarinoBlanco from '../assets/img/albarino-blanco.png';
import CuttySark from '../assets/img/cutty-sark.png';
import EvanWilliams from '../assets/img/evan-williams.png';
import HpNotiq from '../assets/img/hpnotiq.png';
import Mahou from '../assets/img/mahou.png';
import OldParr from '../assets/img/old-parr.png';
import SanPellegrino from '../assets/img/san-pellegrino.png';
import EspolonCristalino from '../assets/img/espolon-cristalino.png';
import { Product } from "interfaces";

export const productsBarlleno: Product[] = [
    {
        key: '1',
        position: 1,
        image: AlbarinoBlanco,
        name: 'Albarino Blanco'
    },
    {
        key: '2',
        position: 2,
        image: CuttySark,
        name: 'Cutty Sark'
    },
    {
        key: '3',
        position: 3,
        image: EvanWilliams,
        name: 'Evan Williams'
    },
    {
        key: '4',
        position: 4,
        image: HpNotiq,
        name: 'HpNotiq'
    },
    {
        key: '5',
        position: 5,
        image: Mahou,
        name: 'Mahou'
    },
    {
        key: '6',
        position: 6,
        image: OldParr,
        name: 'Old Parr'
    },
    {
        key: '7',
        position: 7,
        image: SanPellegrino,
        name: 'San Pellegrino'
    },
    {
        key: '8',
        position: 8,
        image: EspolonCristalino,
        name: 'Espolón Cristalino'
    },
]