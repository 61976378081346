import { Route, Routes } from 'react-router-dom';
import LocationNotAllowedRouter from "./LocationNotAllowedRouter";
import LocationAllowedRouter from "./LocationAllowedRouter";
import HomePage from '../pages/HomePage';
import MenuPage from '../pages/MenuPage';

const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/menu" element={
                    <LocationNotAllowedRouter>
                        <MenuPage />
                    </LocationNotAllowedRouter>
                }
                />

                <Route path="/*" element={
                    <LocationAllowedRouter>
                        <HomePage />
                    </LocationAllowedRouter>
                } />
            </Routes>
        </>
    )
}

export default AppRouter
