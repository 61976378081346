// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa6";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import '../../styles/ElSalvador/ProductsCarousel.css';

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import BarllenoImagotipo from '../../assets/img/barlleno-imagotipo.png';
import { UserOpinion } from "interfaces";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const ValorationsCarousel = ({ opinions }: { opinions: UserOpinion[] }) => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": false
                }}
                className="mySwiper"
            >
                {
                    opinions.map((opinion: UserOpinion) => (
                        <SwiperSlide key={opinion.key}>
                            <div className='bg-transparent px-4'>
                                <div className='bg-gray-FA flex flex-col items-center rounded-xl pt-4'>
                                    <div className='bg-barlleno-red p-4 rounded-lg'>
                                        <img src={BarllenoImagotipo} alt='Barlleno'></img>
                                    </div>

                                    <div className='w-full flex flex-row justify-center py-4'>
                                        <FaStar className='me-1' color='#FF9933' />
                                        <FaStar className='mx-1' color='#FF9933' />
                                        <FaStar className='mx-1' color='#FF9933' />
                                        <FaStar className='mx-1' color='#FF9933' />
                                        <FaStar className='ms-1' color='#FF9933' />
                                    </div>

                                    <div className='text-center'>
                                        <p className='text-2xl font-bold text-barlleno-blue'>{opinion.name}</p>
                                    </div>

                                    <div className='w-full text-center pt-3'>
                                        <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>{opinion.comment}</p>
                                    </div>
                                </div>

                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    )
}

export default ValorationsCarousel;