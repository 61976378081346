import { LocationState } from "./LocationProvider";

type LocationAction = { type: 'setUserLocation', payload: [number?, number?, string?] };

export const locationReducer = ( state: LocationState , action: LocationAction ): LocationState => {
    switch ( action.type) {
        case 'setUserLocation':
            return {
                ...state,
                isLoading: false,
                longitude: action.payload[0],
                latitude: action.payload[1],
                countryCode: action.payload[2]
            }
        default:
            return state;
    }
}