import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { LocationContext } from '../context';

const LocationAllowedRouter = ( {children} : {children: any}) => {
    const { countryCode } = useContext( LocationContext );
    
    return (countryCode !== undefined) 
    ? children
    : <Navigate to="/menu" />
}

export default LocationAllowedRouter;