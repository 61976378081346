// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/InstagramPostsCarouselLaptop.css"

// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper/core';

import Post1 from '../../assets/nimg/rd/ig-post/post1.jpg';
import Post2 from '../../assets/nimg/rd/ig-post/post2.jpg';
import Post3 from '../../assets/nimg/rd/ig-post/post3.jpg';
import Post4 from '../../assets/nimg/rd/ig-post/post4.jpg';
import BarllenoRd from '../../assets/nimg/rd/ig-post/barllenord.jpg';
import Post5 from '../../assets/nimg/rd/ig-post/post5.jpg';
import Post6 from '../../assets/nimg/rd/ig-post/post6.jpg';
import Post7 from '../../assets/nimg/rd/ig-post/post7.jpg';
import Post8 from '../../assets/nimg/rd/ig-post/post8.jpg';

// install Swiper modules
SwiperCore.use([Navigation]);

const InstagramPostCarouselLaptop = () => {
    return (
        <>
            <Swiper 
                slidesPerView={3} 
                spaceBetween={30} 
                slidesPerGroup={3} 
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}  
                loopFillGroupWithBlank={true} 
                navigation={true} 
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post1} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post2} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post3} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post4} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={BarllenoRd} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post5} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post6} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post7} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Post8} alt='Post' className="w-full"></img>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default InstagramPostCarouselLaptop;