import { useContext } from "react";
import { Navigate } from 'react-router-dom';
import { LocationContext } from "../context";

const LocationNotAllowedRouter = ( {children}: {children: any} ) => {
    const { countryCode } = useContext( LocationContext );
    
    return (countryCode === undefined) 
    ? children
    : <Navigate to="/home" />
}

export default LocationNotAllowedRouter;