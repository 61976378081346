import axios from "axios";

const MAPBOX_API_TOKEN = "pk.eyJ1IjoiZGV2cHJlbXRlIiwiYSI6ImNsbjNoYjlocDA5NHUyaXFzMTFhY2c0MDQifQ.LCJYGiirM4pCX7z-2zsxJQ";

export const getUserCountry = async ( longitude: number, latitude: number ) => {
    let countryCode: string = '';
    const lon = longitude.toString();
    const lat = latitude.toString();

    await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${MAPBOX_API_TOKEN}`)
    .then( (response) => {
        let featuresSize = response.data.features.length;
        countryCode = response.data.features[featuresSize - 1].properties.short_code;
    }).catch( (err) => {
        console.log(err.message);
        console.log(err.response?.data);
    })

    return countryCode;
}