// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/ShopNowCarousel.css";

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import Promo1 from '../../assets/nimg/rd/banner/promo1.png';
import Promo3 from '../../assets/nimg/rd/banner/promo3.png';
import Promo6 from '../../assets/nimg/rd/banner/promo6.png';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const ShopNowCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Promo1} alt='Promo 1' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Promo3} alt='Promo 3' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Promo6} alt='Promo 6' className="w-full"></img>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default ShopNowCarousel;