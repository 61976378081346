import axios from 'axios';
import { Product } from 'interfaces';
import { Dispatch, SetStateAction } from 'react';

export function getProductsService(
    setProductsLoaded: Dispatch<SetStateAction<boolean>>,
    setProducts: Dispatch<SetStateAction<Product[]>>
) {
    axios.get(`${process.env.REACT_APP_API_URL}/productsLanding`)
    .then((response) => {
        const productsArray = response.data.data;

        let products: Product[] = [];

        if (productsArray) {
            productsArray.forEach((item: any) => {
                const product: Product = {
                    key: item.brickKey,
                    name: item.name,
                    position: item.position,
                    image: item.image
                };

                products.push(product);
            });

            setProducts(products);
            setProductsLoaded(true);
        } else {
            throw new Error("Error al recuperar los productos");
        }
    }).catch((error) => {
        console.log(error);
    });
} 