import Header from "../components/Header";
import MainSectionRepDominicana from "../components/MainSectionRepDominicana";
import { countriesCode } from "../helpers/countriesCode";

const RepDominicanaPage = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <Header countryCode={countriesCode.RepDominicana} />
            <div className="w-full pt-20 flex flex-col items-center 2xl:container">
                <MainSectionRepDominicana />
            </div> 
        </div>
    )
}

export default RepDominicanaPage;