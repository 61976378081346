import { useEffect, useReducer } from 'react'
import { LocationContext } from "./LocationContext"
import { locationReducer } from './locationReducer'
import { getUserLocation } from '../helpers'
import { getUserCountry } from '../services/getUserCountryService'

export interface LocationState {
    isLoading: boolean,
    longitude?: number,
    latitude?: number,
    countryCode?: string
}

const INITIAL_STATE: LocationState = {
    isLoading: true,
    longitude: undefined,
    latitude: undefined,
    countryCode: undefined
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

export const LocationProvider = ( {children}: Props ) => {
    const [state, dispatch] = useReducer(locationReducer, INITIAL_STATE);
    
    useEffect( () => {
        getUserLocation()
            .then( lnglat => {
                getUserCountry(lnglat[0], lnglat[1])
                    .then( result => {
                        let longitude = lnglat[0];
                        let latitude = lnglat[1];
                        sessionStorage.removeItem('country');
                        dispatch({type: 'setUserLocation', payload: [longitude, latitude, result]});
                    });
            })
            .catch( err => dispatch({type: 'setUserLocation', payload: [undefined, undefined, undefined]}) )
    }, [])

    const setCountry = ( country: string ) => {
        dispatch( {type: 'setUserLocation', payload: [undefined, undefined, country]});
    }

    return (
    <LocationContext.Provider value={{...state, setCountry}}>
        { children }
    </LocationContext.Provider>
  )
}
