// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/CocktailsCarousel.css";

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import Cocktail1 from '../../assets/nimg/rd/cocktails/cocktail1.webp';
import Cocktail2 from '../../assets/nimg/rd/cocktails/cocktail2.webp';
import Cocktail3 from '../../assets/nimg/rd/cocktails/cocktail3.webp';
import Cocktail4 from '../../assets/nimg/rd/cocktails/cocktail4.webp';
import Cocktail5 from '../../assets/nimg/rd/cocktails/cocktail5.webp';
import Cocktail6 from '../../assets/nimg/rd/cocktails/cocktail6.webp';
import Cocktail7 from '../../assets/nimg/rd/cocktails/cocktail7.webp';
import Cocktail8 from '../../assets/nimg/rd/cocktails/cocktail8.webp';
import Cocktail9 from '../../assets/nimg/rd/cocktails/cocktail9.webp';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const CocktailsCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail1} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail2} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail3} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail4} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail5} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail6} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail7} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail8} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={Cocktail9} alt='cocktail' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default CocktailsCarousel;