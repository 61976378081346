import { LocationProvider } from './context';
import AppRouter from './router/AppRouter';

function App() {
    return (
      <LocationProvider>
        <AppRouter />
      </LocationProvider>
    );
}

export default App;
