// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/CaracteristicasBarllenoCarousel.css";

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import Caracteristica1 from '../../assets/nimg/rd/CUADROS/DETODOPARATOMARBLANCO.svg';
import Caracteristica2 from '../../assets/nimg/rd/CUADROS/Pedidosentiemporecordblanco.svg';
import Caracteristica3 from '../../assets/nimg/rd/CUADROS/SIEMPREFRIOBLANCO.svg';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const CaracteristicasBarllenoCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Caracteristica1} alt='Promo 1' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Caracteristica2} alt='Promo 3' className="w-full"></img>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Caracteristica3} alt='Promo 6' className="w-full"></img>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default CaracteristicasBarllenoCarousel;