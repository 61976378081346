// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/AppFunctionsCarousel.css";

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import DownloadApp from '../../assets/nimg/rd/como/Descargalaappgris.svg';
import CreateYourAccount from '../../assets/nimg/rd/como/Creatucuentagris.svg';
import ChooseYourDrink from '../../assets/nimg/rd/como/Eligetubebidafavoritablanco.svg';
import PayMethod from '../../assets/nimg/rd/como/PAGAENTARJETAOEFECTIVOGRIS.svg';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const AppFunctionsCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3000,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={DownloadApp} alt='Descarga el APP' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={CreateYourAccount} alt='Crea tu cuenta' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={ChooseYourDrink} alt='Escoge tu bebida favorita' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <div>
                            <img src={PayMethod} alt='Métodos de pago' className="w-full"></img>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default AppFunctionsCarousel;