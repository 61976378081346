import { createContext } from "react";

export interface LocationContextProps {
    isLoading: boolean,
    longitude?: number,
    latitude?: number,
    countryCode?: string,
    setCountry: any
}

export const LocationContext = createContext( {} as LocationContextProps );