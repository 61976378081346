import React, { useContext } from 'react';
import { LocationContext } from '../context';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { FaLocationPin, FaEnvelope, FaPhone, FaAngleDown } from "react-icons/fa6";
import LogoBarllenoHorizontal from '../assets/img/barlleno-logo-horizontal.png';
import HondurasIcon from '../assets/icons/honduras-icon.png';
import ColombiaIcon from '../assets/icons/colombia-icon.png';
import RepDominicanaIcon from '../assets/icons/republica-dominicana-icon.png';
import GuatemalaIcon from '../assets/icons/guatemala-icon.png';
import ElSalvadorIcon from '../assets/icons/el-salvador-icon.png';
import { countriesCode } from '../helpers/countriesCode';

const Header = ({ countryCode }: { countryCode: string | undefined }) => {
    const { setCountry } = useContext(LocationContext);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    let countryFlag: string = '';
    let countryName: string = '';
    let addres: string = '';
    let phoneNumber: string = '';
    let contactMail: string = '';

    switch (countryCode) {
        case countriesCode.Honduras:
            countryFlag = HondurasIcon;
            countryName = 'Honduras';
            addres = '79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador';
            phoneNumber = '+503 21180405';
            contactMail = 'contacto@barlleno.app';
            break;
        case countriesCode.Colombia:
            countryFlag = ColombiaIcon;
            countryName = 'Colombia';
            addres = 'Cra 13 no 90-17 Bodega l, Colombia';
            phoneNumber = '+57 310 2769 987';
            contactMail = 'contacto.bta@barlleno.app';
            break;
        case countriesCode.RepDominicana:
            countryFlag = RepDominicanaIcon;
            countryName = 'Rep. Dominicana';
            addres = 'Erick Leonard Eckman No. 15, República Dominicana';
            phoneNumber = '+1(809) 770 3850';
            contactMail = 'contacto.rd@barlleno.app';
            break;
        case countriesCode.Guatemala:
            countryFlag = GuatemalaIcon;
            countryName = 'Guatemala';
            addres = '13 calle 4-60 zona 10 Ciudad de Guatemala';
            phoneNumber = '+502 41658892';
            contactMail = 'contacto.gt@barlleno.app';
            break;
        case countriesCode.ElSalvador:
            countryFlag = ElSalvadorIcon;
            countryName = 'El Salvador';
            addres = '79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador';
            phoneNumber = '+503 21180405';
            contactMail = 'contacto@barlleno.app';
            break;
        default:
            countryFlag = ElSalvadorIcon;
            countryName = 'El Salvador';
            addres = '79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador';
            phoneNumber = '+503 21180405';
            contactMail = 'contacto@barlleno.app';
            break;
    }

    const ElSalvadorSelectedHandler = (countryCode: string, event: Event | React.SyntheticEvent) => {
        setCountry(countryCode);
        sessionStorage.setItem('country', countryCode);
        handleClose(event);
        window.scrollTo({
            top: 0,
            behavior: "auto"
        });
    }

    const RepDominicanaSelectedHandler = (event: Event | React.SyntheticEvent) => {
        setCountry(countriesCode.RepDominicana);
        sessionStorage.setItem('country', countriesCode.RepDominicana);
        handleClose(event);
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className='w-screen fixed top-0 flex flex-col items-center shadow-lg z-30'>
            <div className='w-full bg-white 2xl:max-w-[1536px]'>
                <div className='flex flex-row justify-between p-4 lg:px-10'>
                    <div className='flex items-center gap-16'>
                        <img className='w-[120px]' src={LogoBarllenoHorizontal} alt="Barlleno logo"></img>

                        {
                            countryCode !== countriesCode.RepDominicana &&
                            <div className='gap-8 items-center hidden lg:flex'>
                                <div className='flex flex-col items-center hover:border-b-4 hover:border-barlleno-blue text-barlleno-red hover:text-barlleno-blue'>
                                    <a href='#benefits'><p className='font-bold cursor-pointer'>BENEFICIOS</p></a>
                                </div>

                                <div className='flex flex-col items-center hover:border-b-4 hover:border-barlleno-blue text-barlleno-red hover:text-barlleno-blue'>
                                <a href='#payment'><p className='font-bold cursor-pointer'>MÉTODOS DE PAGO</p></a>

                                </div>

                                <div className='flex flex-col items-center hover:border-b-4 hover:border-barlleno-blue text-barlleno-red hover:text-barlleno-blue'>
                                    
                                <a href='#products'><p className='font-bold cursor-pointer'>PRODUCTOS</p></a>
                                </div>

                                <div className='flex flex-col items-center hover:border-b-4 hover:border-barlleno-blue text-barlleno-red hover:text-barlleno-blue'>
                                <a href='#testimonials'><p className='font-bold cursor-pointer'>TESTIMONIALES</p></a>
                                    
                                </div>
                            </div>
                        }

                    </div>

                    <div className='flex flex-row items-start'>
                        {
                            countryCode === countriesCode.RepDominicana &&
                            <div className={`hidden ${countryCode !== countriesCode.Honduras ? 'lg:flex' : ''} flex-col me-14`}>
                                <div className='w-full flex flex-row flex-nowrap items-center'>
                                    <FaLocationPin color='#E84137' size='13px' />
                                    <p className='ms-1 text-[13px]'>{addres}</p>
                                </div>

                                <div className='w-full flex flex-row items-center pt-2'>
                                    <div className='flex flex-row justify-center items-center pe-2'>
                                        <FaEnvelope color='#E84137' size='13px' />
                                        <p className='ms-1 text-[13px]'>{contactMail}</p>
                                    </div>

                                    <div className='flex flex-row justify-center items-center ps-2'>
                                        <FaPhone color='#E84137' size='13px' />
                                        <p className='ms-1 text-[13px]'>{phoneNumber}</p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='cursor-pointer h-max'>
                            <Button
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                style={{ fontWeight: 'bold', fontSize: '15px', color: '#112A56' }}
                            >
                                <img className='w-[25px] me-1' src={countryFlag} alt={countryName}></img>
                                {countryCode?.toUpperCase()}
                                <FaAngleDown color='#112A56' size='20px' />
                            </Button>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    <MenuItem onClick={(e) => ElSalvadorSelectedHandler(countriesCode.Honduras, e)}>
                                                        <img className='w-[25px] me-2' src={HondurasIcon} alt='Honduras'></img>
                                                        Honduras
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => ElSalvadorSelectedHandler(countriesCode.Colombia, e)}>
                                                        <img className='w-[25px] me-2' src={ColombiaIcon} alt='Colombia'></img>
                                                        Colombia
                                                    </MenuItem>
                                                    <MenuItem onClick={RepDominicanaSelectedHandler}>
                                                        <img className='w-[25px] me-2' src={RepDominicanaIcon} alt='Rep. Dominicana'></img>
                                                        Rep.Dominicana
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => ElSalvadorSelectedHandler(countriesCode.Guatemala, e)}>
                                                        <img className='w-[25px] me-2' src={GuatemalaIcon} alt='Guatemala'></img>
                                                        Guatemala
                                                    </MenuItem>
                                                    <MenuItem onClick={(e) => ElSalvadorSelectedHandler(countriesCode.ElSalvador, e)}>
                                                        <img className='w-[25px] me-2' src={ElSalvadorIcon} alt='El Salvador'></img>
                                                        El Salvador
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;