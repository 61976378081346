import '../styles/MainSectionElSalvador.css';
import { FaLocationPin, FaEnvelope, FaPhone, FaCircleExclamation } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import AppScreenshot from '../assets/img/app-screenshot.png';
import DownloadAppStore from '../assets/img/appstore.svg';
import DownloadPlayStore from '../assets/img/google.svg';
import DownloadAppGallery from '../assets/img/appgallery.svg';
import CoinsIcon from '../assets/img/Puntos.svg';
import TruckIcon from '../assets/img/Delivery-gratis.svg';
import MapIcon from '../assets/img/Cobertura.svg';
import BarllenoLogoCuadrado from '../assets/img/barlleno-logo-cuadrado.png';
import VisaLogo from '../assets/img/visa-logo.png';
import MastercardLogo from '../assets/img/mastercard-logo.png';
import InstagramLogo from '../assets/img/instagram-logo.png';
import FacebookLogo from '../assets/img/facebook-logo.png';
import TabletScreenshot from '../assets/img/barlleno-screenshot-tablet.png';
import BarllenoPhoneBlack from '../assets/img/barlleno-phone-black.png';
import BarllenoPhoneWhite from '../assets/img/barlleno-phone-white.png';
import ProductsCarousel from './ElSalvador/ProductsCarousel';
import ProductsCarouselLargeScreen from './ElSalvador/ProductsCarouselLargeScreen';
import ValorationsCarousel from './ElSalvador/ValorationsCarousel';
import RecordTime from '../assets/img/Tiempo-record.svg';
import ExtendedHours from '../assets/img/Horario-extendido.svg';
import DeliveryCost from '../assets/img/Delivery.svg';
import CardsIcon from '../assets/img/Tarjetas.svg';
import PosIcon from '../assets/img/Pos.svg';
import CashIcon from '../assets/img/Efectivo.svg';
import InstallmentsPayment from '../assets/img/pay-methods/pago-plazos.png';
import BarllenoImagotipo from 'assets/img/barlleno-imagotipo.png';
import { productsBarlleno } from '../data/ElSalvadorData';
import { useEffect, useState } from 'react';
import { addCommentService, getCommentsService } from 'services';
import { Product, UserOpinion } from 'interfaces';
import { getProductsService } from 'services/products.service';

const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

const MainSectionElSalvador = () => {
    // Estados para manejar el carrusel de productos
    const [productsLoaded, setProductsLoaded] = useState<boolean>(true);
    const [products, setProducts] = useState<Product[]>([]);

    // Estados para manejar el carrusel de opiniones
    const [opinionsLoaded, setOpinionsLoaded] = useState<boolean>(false);
    const [userOpinions, setUserOpinions] = useState<UserOpinion[]>([]);

    // Estados para manejar el formulario de opiniones
    const [showOpinionForm, setShowOpinionForm] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const [nameFieldTouched, setNameFieldTouched] = useState<boolean>(false);
    const [emailFieldTouched, setEmailFieldTouched] = useState<boolean>(false);
    const [commentFieldTouched, setCommentFieldTouched] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [submittingComment, setSubmittingComment] = useState<boolean>(false);

    const [nameValid, setNameValid] = useState<boolean>(false);
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [commentValid, setCommentValid] = useState<boolean>(false);

    const [nameErrorMessage, setNameErrorMessage] = useState<string>("Ingrese un nombre");
    const [commentErrorMessage, setCommentErrorMessage] = useState<string>("Ingrese un comentario");

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    const changeNameHandler = (name: string) => {
        setNameValid(false);

        if (name.length > 0 && name.length < 6) {
            setNameErrorMessage("El nombre debe tener como mínimo 6 caracteres");
        } else if (name.length === 0) {
            setNameErrorMessage("Ingrese un nombre");
        } else {
            setNameValid(true);
        }

        setName(name);
    }

    const changeEmailHandler = (email: string) => {
        if (validEmail.test(email)) {
            setEmailValid(true);
        } else {
            setEmailValid(false);
        }

        setEmail(email);
    }

    const changeCommentHandler = (comment: string) => {
        setCommentValid(false);

        if (comment.length > 0 && comment.length < 20) {
            setCommentErrorMessage("El comentario debe tener como mínimo 30 caracteres");
        } else if (comment.length === 0) {
            setCommentErrorMessage("Ingrese un comentario");
        } else {
            setCommentValid(true);
        }

        setComment(comment);
    }

    const sendOpinion = () => {
        setFormSubmitted(true);
        setSubmittingComment(true);

        if (nameValid && emailValid && commentValid) {
            addCommentService(name, email, comment, setShowSuccessMessage, setShowOpinionForm, setSubmittingComment, clearForm);
        } else {
            setSubmittingComment(false);
        }
    }

    const makeNewComment = () => {
        setShowOpinionForm(true);
        setShowSuccessMessage(false);
    }

    const clearForm = () => {
        setName("");
        setEmail("");
        setComment("");

        setNameFieldTouched(false);
        setEmailFieldTouched(false);
        setCommentFieldTouched(false);

        setNameValid(false);
        setEmailValid(false);
        setCommentValid(false);

        setFormSubmitted(false);
    }

    useEffect(() => {
        // Función para recuperar los comentarios de los usuarios
        getCommentsService(setOpinionsLoaded, setUserOpinions);

        // Función para recuperar los productos que se muestran en la landing
        getProductsService(setProductsLoaded, setProducts);
    }, []);

    return (
        <div className="flex flex-col items-center w-full">
            <div className="w-full flex flex-col items-center lg:flex-row lg:justify-center">
                <div className='lg:w-[50%] flex flex-row justify-center relative'>
                    <img src={AppScreenshot} alt='Barlleno app' className='w-[250px] lg:hidden'></img>
                    <img src={TabletScreenshot} alt='Barlleno app' className='w-[500px] hidden lg:block'></img>
                    <div className='absolute bottom-10 right-0'>
                        <img src={AppScreenshot} alt='Barlleno app' className='w-[250px] hidden xl:block'></img>
                    </div>
                </div>

                <div className='lg:w-[50%]'>
                    <div className='text-center lg:text-start px-3'>
                        <p className='text-2xl font-bold text-barlleno-blue lg:text-4xl'>Barlleno APP, la primera y única drink delivery app de Latinoamérica</p>
                    </div>

                    <div className='text-center pt-3 lg:text-start pl-3 pr-8'>
                        <p className='text-2xl font-semibold text-barlleno-red text-justify'>Somos la app donde encuentras de todo para tomar desde vinos, destilados, cervezas y todo tipo de bebidas alcohólicas y no alcohólicas de tu preferencia, contamos con horarios extendidos y te entregamos tus bebidas en tiempo récord</p>
                    </div>

                    <div className='w-full flex justify-start pt-3 px-3  '>
                     <a href='https://commerce.barlleno.app/' target='_blank' rel="noreferrer" className='bg-barlleno-red text-white p-4 rounded-full w-full font-bold uppercase text-md lg:w-[70%] hover:-translate-y-1 transform transition-all flex justify-center items-center gap-2'>Comprar ahora</a>
                    </div>

                    <div className='w-full px-3 mt-3'>
                        <p className='font-bold text-barlleno-blue'>DESCARGA LA APP EN LAS SIGUIENTES PLATAFORMAS:</p>
                    </div>

                    <div className='w-full flex justify-between sm:justify-center px-3 lg:justify-start mt-3'>
                        <div>
                            <a href="https://apps.apple.com/us/app/barlleno/id1506149704?l=es&ls=1" target='_blank' rel="noreferrer">
                                <img src={DownloadAppStore} alt='Appstore' className='w-[124px]'></img>
                            </a>
                        </div>

                        <div className='sm:mx-4'>
                            <a href="https://play.google.com/store/apps/details?id=app.barlleno.premier ">
                                <img src={DownloadPlayStore} alt='Playstore' className='w-[140px]'></img>
                            </a>
                        </div>

                        <div>
                            <a href="https://appgallery.huawei.com/#/app/C102795087">
                                <img src={DownloadAppGallery} alt='Appgallery' className='w-[140px]'></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id='benefits' className='w-full h-[70px]'></div>

            <div className='w-full flex flex-col items-center'>
                <div className='text-center lg:w-[60%]'>
                    <p className='text-2xl font-bold text-barlleno-blue lg:text-4xl'>Descarga ya la app y disfruta de la mejor experiencia en drinks</p>
                </div>

                <div className='text-center pt-3 lg:w-[60%]'>
                    <p className='text-2xl font-semibold text-barlleno-red'>Conoce todos nuestros beneficios exclusivos en Barlleno App</p>
                </div>

                <div className='w-full flex flex-col text-barlleno-blue lg:py-4'>
                    <div className='w-full flex flex-col items-center px-4 lg:flex-row lg:justify-evenly gap-4 mt-4 mb-2'>
                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[250px]'>
                            <div>
                                <img src={RecordTime} alt='Tiempo récord' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 px-2'>
                                <p className='text-xl font-semibold'>Pedidos en tiempo récord</p>
                            </div>
                        </div>

                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[250px]'>
                            <div>
                                <img src={ExtendedHours} alt='Horarios extendidos' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 px-2'>
                                <p className='text-xl font-semibold'>Horarios extendidos hasta las 2:00 a.m.</p>
                            </div>
                        </div>

                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[250px]'>
                            <div>
                                <img src={DeliveryCost} alt='Delivery' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 px-2'>
                                <p className='text-xl font-semibold'>Delivery desde $1.49</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center px-4 lg:flex-row lg:justify-evenly gap-4 mt-2 mb-4 lg:mt-4'>
                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[280px] xl:h-[270px]'>
                            <div>
                                <img src={MapIcon} alt='Cobertura nacional' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 px-2'>
                                <p className='text-xl font-semibold'>Cobertura de servicio a domicilio a nivel nacional</p>
                            </div>
                        </div>

                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[280px] xl:h-[270px]'>
                            <div>
                                <img src={TruckIcon} alt='Free delivery' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 2xl:px-4'>
                                <p className='text-xl font-semibold'>Free delivery en productos seleccionados</p>
                            </div>
                        </div>

                        <div className='card w-full flex flex-col items-center justify-center py-4 text-center rounded-2xl lg:w-[30%] lg:h-[280px] xl:h-[270px]'>
                            <div>
                                <img src={CoinsIcon} alt='Acumula puntos' className='w-[150px] h-[150px]'></img>
                            </div>

                            <div className='mt-4 px-2'>
                                <p className='text-xl font-semibold'>Por cada $1 en tus compras acumulas 1 punto para canjear por productos exclusivos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='payment' className='w-full h-[70px]'></div>

            <div className='flex flex-col w-full items-center justify-center gap-4 mb-8 px-4'>
                <p className='text-barlleno-blue font-bold text-4xl text-center'>Contamos con diferentes métodos de pago</p>

                <p className='text-barlleno-red text-2xl font-semibold text-center'>Elige el que mejor te convenga</p>

                <div className='w-full flex flex-col items-center lg:flex-row lg:justify-center gap-8 px-4 mt-6'>
                    <div className='flex flex-col items-center justify-center gap-4 w-full lg:w-1/5'>
                        <div className='bg-gray-valorations p-8 rounded-md lg:w-4/5 flex justify-center items-center'>
                            <img src={CardsIcon} alt='Tarjetas' className='w-[150px] h-[150px] object-contain'></img>
                        </div>

                        <p className='text-barlleno-blue font-bold text-xl'>Tarjetas de crédito y débito</p>
                    </div>

                    <div className='flex flex-col items-center justify-center gap-4 w-full lg:w-1/5'>
                        <div className='bg-gray-valorations p-8 rounded-md lg:w-4/5 flex justify-center items-center'>
                            <img src={PosIcon} alt='Pos' className='w-[150px] h-[150px] object-contain'></img>
                        </div>

                        <p className='text-barlleno-blue font-bold text-xl'>POS</p>
                    </div>

                    <div className='flex flex-col items-center justify-center gap-4 w-full lg:w-1/5'>
                        <div className='bg-gray-valorations p-8 rounded-md lg:w-4/5 flex justify-center items-center'>
                            <img src={CashIcon} alt='Efectivo' className='w-[150px] h-[150px] object-contain'></img>
                        </div>

                        <p className='text-barlleno-blue font-bold text-xl'>Efectivo</p>
                    </div>

                    {/* <div className='flex flex-col items-center justify-center gap-4 w-full lg:w-1/5'>
                        <div className='bg-gray-valorations p-8 rounded-md lg:w-4/5 flex justify-center items-center'>
                            <img src={InstallmentsPayment} alt='Pago a plazos' className='w-[125px] h-[114px]'></img>
                        </div>

                        <p className='text-barlleno-blue font-bold text-xl'>Pago a plazos</p>
                    </div> */}
                </div>
            </div>

            <div id='products' className='w-full h-[70px]'></div>

            <div className='w-full bg-barlleno-red flex flex-col items-center pt-8 px-3 pb-32 lg:pb-16'>
                <div className='text-center'>
                    <p className='text-2xl font-bold text-white'>Únete a la experiencia Barlleno</p>
                </div>

                <div className='text-center py-4'>
                    <p className='text-xl font-semibold text-white'>Encuentra las mejores marcas internaciones de bebidas</p>
                </div>

                <div id='products-carousel' className='w-full my-5 cursor-pointer'>
                    {
                        productsLoaded
                            ? <ProductsCarouselLargeScreen productsToShow={products} />
                            : <ProductsCarouselLargeScreen productsToShow={productsBarlleno} />
                    }
                </div>

                {/* <div id='products-carousel-lg' className='w-full my-5 hidden lg:block cursor-pointer'>
                    <ProductsCarouselLargeScreen productsToShow={productsBarlleno} />
                </div> */}

                <div className='w-full flex justify-center mt-8'>
                    <a href='https://commerce.barlleno.app/' target='_blank' rel="noreferrer" className='w-full lg:w-[40%]'><button className='bg-white text-barlleno-red px-2 py-4 rounded-full w-full font-bold uppercase text-md hover:-translate-y-1 transition-all transform'>Visita nuestro web commerce</button></a>
                </div>

                <div className='w-full flex justify-end'>
                    <img className='absolute w-[210px] lg:w-[250px]' src={BarllenoPhoneBlack} alt='Barlleno'></img>
                </div>
            </div>

            <div className='w-full bg-barlleno-blue flex flex-col items-center pt-40 px-4 pb-20 lg:pt-10 xl:pb-14'>
                <div className='text-center lg:w-[50%]'>
                    <p className='text-2xl font-bold text-white'>¿Quieres pedir tus drinks?</p>
                </div>

                <div className='text-center pt-4 lg:w-[60%]'>
                    <p className='text-xl font-semibold text-white'>Descargá la app o ingresa a nuestro Web Commerce y disfruta de tus bebidas favoritas y las recibirás en tiempo récord en la puerta de tu casa</p>
                </div>

                <div className='w-full flex justify-start items-start'>
                    <img className='absolute w-[210px] sm:w-[250px] lg:w-[250px] mt-8 sm:ms-8 lg:mt-0' src={BarllenoPhoneWhite} alt='Barlleno'></img>
                </div>

                <div className='w-full flex flex-row justify-end pt-6 lg:w-auto lg:flex-col lg:bg-white lg:mt-6 lg:p-6 lg:rounded-lg'>
                    <p className='font-bold text-barlleno-blue'>DESCARGA LA APP EN LAS SIGUIENTES PLATAFORMAS:</p>

                    <div className='flex flex-col items-end w-[35%] md:w-full md:flex-row md:justify-end md:items-center lg:justify-center gap-2 lg:mt-4'>
                        <a href="https://apps.apple.com/us/app/barlleno/id1506149704?l=es&ls=1" target='_blank' rel="noreferrer">
                            <img src={DownloadAppStore} alt='Appstore' className='w-[130px]'></img>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=app.barlleno.premier ">
                            <img src={DownloadPlayStore} alt='Playstore' className='w-[130px] lg:w-[140px] my-1 lg:my-0 lg:mx-4'></img>
                        </a>

                        <a href="https://appgallery.huawei.com/#/app/C102795087">
                            <img src={DownloadAppGallery} alt='Appgallery' className='w-[130px] lg:w-[140px]'></img>
                        </a>
                    </div>
                </div>
            </div>

            <div id='testimonials' className='w-full h-[70px] bg-gray-valorations'></div>

            <div className='w-full flex flex-col items-center pt-12 md:pt-20 lg:pt-0 px-3 pb-8 bg-gray-valorations'>
                <div className='text-center lg:w-[50%]'>
                    <p className='text-2xl font-bold text-barlleno-red'>Nuestros drinkeros</p>
                </div>

                <div className='text-center pt-4 lg:w-[50%]'>
                    <p className='text-2xl font-semibold text-barlleno-red'>¡Únete a nuestra comunidad y disfruta de la experiencia completa!</p>
                </div>

                <div className='px-10 w-full mt-4 pb-6 cursor-pointer'>
                    {
                        opinionsLoaded
                            ? <ValorationsCarousel opinions={userOpinions} />
                            :
                            <div className='w-full flex justify-center items-center'>
                                <div className='bg-barlleno-red p-4 rounded-lg'>
                                    <img src={BarllenoImagotipo} alt='Barlleno'></img>
                                </div>
                            </div>
                    }
                </div>
            </div>

            <div className='w-full flex flex-col items-center py-10 gap-6 px-4 bg-gray-form'>
                {
                    !showOpinionForm && !showSuccessMessage &&
                    <>

                        <p className='text-center text-barlleno-red font-bold'>¿TÚ TAMBIÉN QUIERES SER UN DRINKERO CONOCIDO?</p>

                        <button
                            onClick={() => setShowOpinionForm(true)}
                            className='bg-barlleno-red text-white font-bold px-6 py-3 rounded-full hover:opacity-90'
                        >
                            DÉJANOS TU COMENTARIO AQUÍ
                        </button>
                    </>
                }

                {
                    showOpinionForm &&
                    <div className='w-full flex flex-col items-center gap-4 mt-2'>
                        <div className='px-6 py-3 bg-barlleno-blue text-white text-center rounded-full font-semibold mb-4 w-full sm:w-3/5 xl:w-2/5'>
                            <p>DÉJANOS TU COMENTARIO AQUÍ PARA SER UN DRINKERO CONOCIDO</p>
                        </div>

                        <div className='flex flex-col w-full sm:w-3/5 xl:w-2/5 gap-1'>
                            <input
                                type='text'
                                name='name'
                                id='name'
                                value={name}
                                placeholder='Nombre'
                                autoComplete='off'
                                onChange={(e) => changeNameHandler(e.target.value)}
                                onBlur={() => setNameFieldTouched(true)}
                                className={`w-full text-center p-2 text-barlleno-blue border border-solid focus:outline-none rounded-full ${!nameFieldTouched && !formSubmitted && ' border-gray-300 focus:border-barlleno-blue'} ${(nameFieldTouched || formSubmitted) && !nameValid ? 'border-danger' : 'border-gray-300'} ${nameValid && ' focus:border-barlleno-blue'}`}
                            />

                            {
                                (nameFieldTouched || formSubmitted) && !nameValid &&
                                <div className='w-full flex items-center justify-center gap-2'>
                                    <FaCircleExclamation color='#B3261E' size='16px' />

                                    <p className=' text-danger text-lg font-semibold'>{nameErrorMessage}</p>
                                </div>
                            }
                        </div>


                        <div className='flex flex-col w-full sm:w-3/5 xl:w-2/5 gap-1'>
                            <input
                                type='text'
                                name='email'
                                id='email'
                                value={email}
                                placeholder='Correo'
                                autoComplete='off'
                                onChange={(e) => changeEmailHandler(e.target.value)}
                                onBlur={() => setEmailFieldTouched(true)}
                                className={`w-full text-center p-2 text-barlleno-blue border border-solid focus:outline-none rounded-full ${!emailFieldTouched && !formSubmitted && ' border-gray-300 focus:border-barlleno-blue'} ${(emailFieldTouched || formSubmitted) && !emailValid ? ' border-danger' : ' border-gray-300'} ${emailValid && ' focus:border-barlleno-blue'}`}
                            />

                            {
                                (emailFieldTouched || formSubmitted) && !emailValid &&
                                <div className='w-full flex items-center justify-center gap-2'>
                                    <FaCircleExclamation color='#B3261E' size='16px' />

                                    <p className=' text-danger text-lg font-semibold'>Ingrese un email válido</p>
                                </div>
                            }
                        </div>

                        <div className='flex flex-col w-full sm:w-3/5 xl:w-2/5 gap-1'>
                            <textarea
                                name='comment'
                                id='comment'
                                value={comment}
                                placeholder='Escribe aquí tu comentario'
                                autoComplete='off'
                                onChange={(e) => changeCommentHandler(e.target.value)}
                                onBlur={() => setCommentFieldTouched(true)}
                                className={`w-full text-center p-4 text-barlleno-blue border border-solid focus:outline-none rounded-lg min-h-[90px] text-area ${!commentFieldTouched && !formSubmitted && ' border-gray-300 focus:border-barlleno-blue'} ${(commentFieldTouched || formSubmitted) && !commentValid ? 'border-danger' : 'border-gray-300'} ${commentValid && ' focus:border-barlleno-blue'}`}
                            />

                            {
                                (commentFieldTouched || formSubmitted) && !commentValid &&
                                <div className='w-full flex items-center justify-center gap-2'>
                                    <FaCircleExclamation color='#B3261E' size='16px' />

                                    <p className=' text-danger text-lg font-semibold'>{commentErrorMessage}</p>
                                </div>
                            }
                        </div>

                        <button
                            onClick={sendOpinion}
                            disabled={submittingComment}
                            className={`bg-barlleno-red text-white font-bold px-6 py-3 rounded-full hover:opacity-90 w-full sm:w-3/5 xl:w-2/5 ${submittingComment && 'opacity-70'}`}
                        >
                            ENVIAR
                        </button>
                    </div>
                }

                {
                    showSuccessMessage &&
                    <div className='w-full flex flex-col items-center mt-2'>
                        <FaCheckCircle color='#39C408' size="90px" />

                        <p className='text-2xl text-barlleno-blue font-semibold my-8 text-center' >Tu comentario ha sido recibido y muy pronto podrás verlo en nuestra sección de drinkeros conocidos</p>

                        <button
                            onClick={makeNewComment}
                            className='bg-barlleno-red text-white font-bold px-6 py-3 rounded-full hover:opacity-90 w-full sm:w-3/5 xl:w-2/5'
                        >
                            DEJAR OTRO COMENTARIO
                        </button>
                    </div>
                }
            </div>

            <div className='w-full flex flex-col items-center lg:flex-row-reverse lg:justify-around pt-8'>
                <div className='w-full flex flex-col items-center lg:w-[70%]'>
                    <div className='w-full flex flex-col items-center p-2 sm:flex-row sm:justify-between'>
                        <div className='w-full flex flex-col px-2 mb-4 sm:mb-0 sm:w-[50%]'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>El Salvador</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+503 21180405</p>
                                </div>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>Av. de la Revolución, San Salvador, El Salvador</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+503 71229947</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex flex-col px-2 sm:w-[50%] sm:ps-10 md:ps-14'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Guatemala</p>
                            </div>

                            <div className='flex flex-row sm:flex-end'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>13 calle 4-60 zona 10 Ciudad de Guatemala</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.gt@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+502 41658892</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center p-2 sm:flex-row sm:justify-between'>
                        <div className='w-full flex flex-col px-2 mb-4 sm:w-[50%]'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Colombia</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>Cra 13 no 90-17 Bodega l, Colombia</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.bta@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+57 310 2769 987</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex flex-col px-2 sm:ps-10 sm:w-[50%] md:ps-14'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Rep. Dominicana</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>Erick Leonard Eckman No. 15, República Dominicana</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.rd@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+1(809) 770 3850</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex flex-col pt-8 lg:w-[30%]'>
                    <div className='flex flex-row justify-center'>
                        <img src={BarllenoLogoCuadrado} alt='Barlleno' className='w-[200px]'></img>
                    </div>

                    <div className='flex flex-row justify-evenly items-center py-5'>
                        <div>
                            <img src={VisaLogo} alt='Visa' className='w-[50px]'></img>
                        </div>
                        <div>
                            <img src={MastercardLogo} alt='Mastercard' className='w-[30px]'></img>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/barlleno/" target='_blank' rel="noreferrer"><img src={InstagramLogo} alt='Instagram' className='w-[25px]'></img></a>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/barlleno" target='_blank' rel="noreferrer"><img src={FacebookLogo} alt='Facebook' className='w-[25px]'></img></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-row justify-center'>
                <hr className='w-[95%] border-solid border-gray'></hr>
            </div>

            <div className='w-full text-center py-5 px-4'>
                <p className='text-md font-medium text-barlleno-blue'>Copyright © {new Date().getFullYear()}  All rights reserved | <a href='https://premte.com/' target='_blank' rel="noreferrer" className='text-barlleno-red underline cursor-pointer'>Premier Tec</a></p>
            </div>
        </div>
    )
}

export default MainSectionElSalvador;