import axios from 'axios';
import { UserOpinion } from 'interfaces';
import { Dispatch, SetStateAction } from 'react';

export function addCommentService(
    name: string, 
    email: string, 
    comment: string, 
    showSuccessMessage: Dispatch<SetStateAction<boolean>>,
    showOpinionForm: Dispatch<SetStateAction<boolean>>,
    submittingComment: Dispatch<SetStateAction<boolean>>,
    clearForm: () => void
) {
    axios.post(`${process.env.REACT_APP_API_URL}/opinions`, {
        name,
        email,
        comment,
    }).then(() => {
        showSuccessMessage(true);
        clearForm();
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        showOpinionForm(false);
        submittingComment(false);
    })
}

export function getCommentsService(
    setOpinionsLoaded: Dispatch<SetStateAction<boolean>>,
    setUsersOpinions: Dispatch<SetStateAction<UserOpinion[]>>
) {
    axios.get(`${process.env.REACT_APP_API_URL}/opinions?limit=20`)
    .then((response) => {
        const opinionsArray = response.data.data;

        let usersOpinions: UserOpinion[] = [];

        if (opinionsArray) {
            opinionsArray.forEach((item: any) => {
                const opinion: UserOpinion = {
                    key: item.id,
                    name: item.data.name,
                    comment: item.data.comment
                };

                usersOpinions.push(opinion);
            });

            setUsersOpinions(usersOpinions);
            setOpinionsLoaded(true);
        } else {
            throw new Error("Error al recuperar los comentarios de los usuarios");
        }
    }).catch((error) => {
        console.log(error);
    });
}